@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600&display=swap");
#Hero {
  position: relative;
  text-align: left;
  display: flex;
  align-items: center;
  height: clamp(35rem, 80vh, 50rem);
  width: 100vw;
}
#Hero::before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.6;
  background-color: #E2F0FA;
  background-image: linear-gradient(to top, transparent 0%, transparent 92%, #C9F1FF 100%), url("../../img/backgrounds/Blue-Gloves-Heart.jpg");
  background-image: linear-gradient(to top, transparent 0%, transparent 92%, #C9F1FF 100%), url("../../img/backgrounds/Blue-Gloves-Heart.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}
#Hero h2,
#Hero p {
  color: #005681 !important;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.123);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
#Hero h1 {
  margin: 0;
}
#Hero h2 {
  font-size: clamp(2rem, 8vw, 3.5rem);
  line-height: 1;
}
#Hero h3 {
  color: hotpink;
}
#Hero h3 span {
  color: #005681;
  font-weight: 300;
}
#Hero p {
  font-weight: 300;
  font-size: 1.4rem;
}
#Hero a {
  font-weight: 400;
  font-size: 1.1rem;
  padding: 1.35em 2em;
  border: none;
  background: linear-gradient(to top right, rgb(221, 98, 117), rgb(230, 89, 159));
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}
#Hero a:hover, #Hero a:focus {
  background: none;
  color: hotpink;
  outline: 2px solid hotpink;
}
#Hero #All-in-one {
  font-size: 80%;
}

#Banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  background-color: #fefefe;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.4);
  border-radius: 1000px;
  min-height: 100px;
  transform: translateY(-50%);
}
#Banner .banner-box {
  max-height: 80px;
  width: 30%;
  text-align: center;
}
#Banner .banner-box .iconify {
  font-size: 28px;
}
#Banner .banner-box .bbb {
  height: 55px;
  width: 30px;
  border-radius: 8px;
}
#Banner .banner-box p {
  font-size: 16px;
  display: inline;
}
#Banner .stat {
  font-size: 20px;
  margin: 0;
  line-height: 1.25;
  font-weight: 400;
}
#Banner .stat #Banner-count {
  font-size: 24px;
}

#About {
  display: flex;
  position: relative;
  z-index: 0;
  flex-direction: column;
  padding: max(3vh, 50px) 0;
  color: white;
  background: linear-gradient(to top, rgba(48, 55, 71, 0.9), rgba(16, 38, 53, 0.952)), url("../../img/backgrounds/service-backgrounds/wiping.jpg") no-repeat;
  background-size: cover;
  background-attachment: fixed;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
#About .text-box p,
#About .text-box h1 {
  color: white;
  font-weight: 300;
  font-size: 18px;
}
#About .text-box p:first-child,
#About .text-box h1:first-child {
  font-weight: bold;
  font-size: 26px;
  line-height: 1.25;
}
#About .text-box p:first-child span,
#About .text-box h1:first-child span {
  font-size: 75%;
  font-weight: 200;
}
#About .text-box .check {
  font-size: 18px;
  color: #C9F1FF;
}
#About .text-box .check span {
  display: flex;
  font-weight: bold;
  font-size: 1.25em;
  margin-bottom: 1%;
}
#About .text-box .check span::before {
  content: "✔";
  display: inline-block;
  color: hotpink;
  height: 10px;
  width: 10px;
  margin-right: 15px;
}
#About .line {
  height: 2px;
  width: 75%;
  background-color: white;
  opacity: 0.5;
  margin: 5% auto;
}
#About .perks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
#About .perks .perk {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  width: -moz-max-content;
  width: max-content;
  max-width: 150px;
}
#About .perks .perk .iconify {
  font-size: 60px;
  color: white;
  margin: 10px;
}
#About .perks .perk p {
  color: #C9F1FF;
  font-size: 16px;
  margin: 0;
  line-height: 18px;
  font-weight: 300;
}

#Satisfaction {
  padding: 12vh 0;
  margin-bottom: 8vh;
  background: linear-gradient(to bottom right, #00293d, #2179a5, #00293d);
  color: white;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
}
#Satisfaction h2 {
  font-size: 2.5rem;
}
#Satisfaction p {
  font-weight: bold;
  color: white;
  font-size: 1.75rem;
  line-height: 1.25;
}
#Satisfaction .ribbon {
  margin: 1rem 0;
  width: min(240px, 80%);
  filter: drop-shadow(4px 4px 1rem rgba(0, 0, 0, 0.4));
  padding: 1rem;
}

#Services {
  max-width: 1500px;
  margin: 0 auto;
}
#Services .service-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
#Services .service-grid .service-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30vh;
  min-height: 350px;
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: 0.2s;
}
#Services .service-grid .service-button:hover {
  background-position: top;
}
#Services .service-grid .service-button:hover .icon {
  opacity: 1;
  font-size: 60px;
  margin-bottom: 10px;
}
#Services .service-grid .service-button:hover p {
  opacity: 1;
}
#Services .service-grid .service-button .icon {
  font-size: 50px;
  opacity: 0.8;
  transition: 0.2s;
}
#Services .service-grid .service-button .blink {
  display: none;
  font-size: 35px;
}
#Services .service-grid .service-button h2 {
  font-size: 40px;
}
#Services .service-grid .service-button p {
  color: white;
  opacity: 0.8;
  transition: 0.2s;
}
#Services .service-grid .service-button[data-service=residential] {
  background-image: linear-gradient(to top right, rgba(0, 86, 129, 0.85), rgba(26, 190, 212, 0.85)), url("../../img/backgrounds/service-backgrounds/residential.webp");
  border-top-left-radius: 15px;
}
#Services .service-grid .service-button[data-service=commercial] {
  background-image: linear-gradient(to top right, rgba(1, 104, 56, 0.85), rgba(31, 209, 141, 0.85)), url("../../img/backgrounds/service-backgrounds/commercial.webp");
  border-top-right-radius: 15px;
}
#Services .service-grid .service-button[data-service=biohazard] {
  background-image: linear-gradient(to top right, rgba(100, 36, 25, 0.85), rgba(199, 70, 53, 0.85)), url("../../img/backgrounds/service-backgrounds/biohazard-cleaning.jpg");
  border-bottom-left-radius: 15px;
}
#Services .service-grid .service-button[data-service=rentals] {
  background-image: linear-gradient(to top right, rgba(100, 36, 25, 0.85), rgba(199, 70, 53, 0.85)), url("../../img/backgrounds/service-backgrounds/rental-apartment.jpg");
  border-bottom-left-radius: 15px;
}
#Services .service-grid .service-button[data-service=additional] {
  background-image: linear-gradient(to top right, rgba(129, 0, 90, 0.85), rgba(212, 26, 150, 0.85)), url("../../img/backgrounds/service-backgrounds/additional.jpg");
  border-bottom-right-radius: 15px;
}

#Video {
  margin: 5rem 0;
  padding: 0 2vw;
}
#Video video {
  border-radius: 1rem;
  box-shadow: 0.2rem 0.2rem 2rem rgba(0, 0, 0, 0.5);
  width: min(100%, 1000px);
  padding: 0;
}

#Before-After {
  --default-slider-pos: 25%;
  padding-bottom: 10vh;
}
#Before-After .contain {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
#Before-After .contain .ba-item {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 35vh;
  min-height: 200px;
  margin: 20px;
  box-shadow: 0 0 15px dimgray;
  opacity: 0.85;
  transition: 0.15s;
}
#Before-After .contain .ba-item .before,
#Before-After .contain .ba-item .after {
  position: absolute;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#Before-After .contain .ba-item .after {
  z-index: 1;
}
#Before-After .contain .ba-item input {
  position: absolute;
  z-index: 3;
  height: 100%;
  width: calc(100% + 80px);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  cursor: col-resize;
  animation: flash 0.4s infinite alternate;
}
#Before-After .contain .ba-item input::-webkit-slider-thumb, #Before-After .contain .ba-item input::-moz-range-thumb {
  -webkit-appearance: none;
  border: none;
  height: 80px;
  width: 80px;
  background: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="80px" height="80px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 256 256"%3E%3Cpath fill="white" d="M134 40v176a6 6 0 0 1-12 0V40a6 6 0 0 1 12 0Zm-38 82H30.5l21.7-21.8a5.9 5.9 0 0 0-8.4-8.4l-32 32a5.8 5.8 0 0 0 0 8.4l32 32a5.9 5.9 0 0 0 8.4-8.4L30.5 134H96a6 6 0 0 0 0-12Zm148.2 1.8l-32-32a5.9 5.9 0 0 0-8.4 8.4l21.7 21.8H160a6 6 0 0 0 0 12h65.5l-21.7 21.8a5.9 5.9 0 1 0 8.4 8.4l32-32a5.8 5.8 0 0 0 0-8.4Z"%2F%3E%3C%2Fsvg%3E');
  background-size: cover;
}
#Before-After .contain .ba-item .strip {
  display: block;
  position: absolute;
  left: var(--default-slider-pos);
  transform: translateX(-50%);
  z-index: 2;
  height: 100%;
  width: 2px;
  background-color: white;
  box-shadow: 0 0 10px #1abfd4;
  animation: flash 0.4s infinite alternate;
}
#Before-After .contain .ba-item:hover {
  opacity: 1;
  transform: scale(1.02);
}
@keyframes flash {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

#FAQ {
  background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.85)), url("../../img/backgrounds/Blue-Gloves-Heart.jpg");
  background-position: top right;
  background-attachment: fixed;
  background-repeat: repeat;
  background-size: cover;
  padding: 15vh 0;
}
#FAQ .accordion-item {
  background-color: rgba(255, 255, 255, 0.5);
  margin: 10px auto;
  max-width: 1000px;
}
#FAQ .accordion-item:focus {
  outline: 0;
}
#FAQ .accordion-item:nth-of-type(2n) {
  background-color: rgba(29, 97, 131, 0.1921568627);
}
#FAQ .accordion-item:nth-of-type(2n) .accordion-button .iconify {
  color: #005681;
}
#FAQ .accordion-button {
  --accordion-font-size: 20px;
  font-size: var(--accordion-font-size);
  background-color: rgba(255, 255, 255, 0.5);
}
#FAQ .accordion-button p {
  margin: 0;
  font-size: var(--accordion-font-size);
}
#FAQ .accordion-button .iconify {
  margin-right: 20px;
  font-size: var(--accordion-font-size);
  color: hotpink;
}
#FAQ .accordion-button:focus {
  box-shadow: none;
}
#FAQ .accordion-body {
  color: #005681;
  text-align: start;
}
#FAQ .accordion-body .iconify {
  margin-right: 10px;
}
#FAQ .accordion-body p {
  display: inline;
  font-size: 18px;
}

#Contact {
  background: linear-gradient(to bottom, #1abfd4, #005681);
  padding: 200px 0;
}
#Contact h1 {
  color: white;
  margin: 0 20px;
}
#Contact .alert {
  display: none;
  color: lightgreen;
}
#Contact a {
  font-size: 18px;
  background-color: white;
  color: #005681;
  padding: 0.9rem 1.9rem;
  border-radius: 100px;
  margin-top: 20px;
  border: 2px solid white;
  transition: 0.4s;
  margin: 20px 0;
}
#Contact a:hover {
  background-color: transparent;
  color: white;
  box-shadow: 0 0 10px white;
}

#Meet-The-Team {
  padding: 8vh 0;
}
#Meet-The-Team .container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
#Meet-The-Team .container .TeamMember {
  transition: transform 0.15s ease;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  padding: 5px;
  margin: 12px max(15px, 2vw);
}
#Meet-The-Team .container .TeamMember:hover {
  transform: scale(1.05);
}
#Meet-The-Team .container .TeamMember img {
  border-radius: 100%;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
  width: 175px;
  height: 175px;
  margin-bottom: 5%;
}
#Meet-The-Team .container .TeamMember h1 {
  font-size: clamp(20px, 5vw, 26px);
  margin: 0;
  line-height: 100%;
  margin-bottom: 8px;
  font-weight: 500;
}
#Meet-The-Team .container .TeamMember h2 {
  font-size: clamp(16px, 4vw, 18px);
  font-weight: 300;
  margin: 0;
  line-height: 100%;
}
#Meet-The-Team .container .TeamMember h2:nth-of-type(2) {
  margin-top: 5px;
}
#Meet-The-Team .container .TeamMember .website {
  font-size: 16px;
  font-weight: 300;
}
#Meet-The-Team .container .TeamMember .socials {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
#Meet-The-Team .container .TeamMember .socials a {
  margin: 0 7px;
  color: #005681;
}
#Meet-The-Team .container .TeamMember .socials a:hover {
  color: #1abfd4;
}

@media screen and (max-width: 1199px) {
  #Hero:before {
    opacity: 0.6;
  }
  #Additional-Services .service-grid {
    grid-template-columns: 1fr;
  }
  #Before-After .contain {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 991px) {
  #Hero:before {
    background-position: top;
  }
  #Banner {
    flex-direction: column;
  }
  #Banner .banner-box {
    width: -moz-max-content;
    width: max-content;
    margin: 20px 0;
  }
  #Services .service-grid,
  #Additional-Services .service-grid {
    grid-template-columns: 1fr;
  }
  #Services .service-grid .service-button[data-service=residential], #Services .service-grid .service-button[data-service=commercial], #Services .service-grid .service-button[data-service=biohazard], #Services .service-grid .service-button[data-service=rentals], #Services .service-grid .service-button[data-service=additional],
  #Additional-Services .service-grid .service-button[data-service=residential],
  #Additional-Services .service-grid .service-button[data-service=commercial],
  #Additional-Services .service-grid .service-button[data-service=biohazard],
  #Additional-Services .service-grid .service-button[data-service=rentals],
  #Additional-Services .service-grid .service-button[data-service=additional] {
    border-radius: unset;
  }
  #Services .service-grid .service-button .blink,
  #Additional-Services .service-grid .service-button .blink {
    display: block;
    animation: blink 0.5s alternate infinite;
  }
}
@media screen and (max-width: 575px) {
  #Hero #All-in-one {
    font-size: 60%;
    font-weight: 700;
  }
  #Banner {
    border-radius: 0;
    transform: unset;
  }
  #Services .services-grid {
    grid-template-columns: 1fr;
  }
}/*# sourceMappingURL=home.css.map */