@use '../globals';

#Contact-Form {
    display: flex;
    justify-content: center;
    margin-bottom: 20vh;

    .form-card {
        display: none;
        flex-direction: column;
        align-items: center;
        box-shadow: 0 0 12px rgba(0, 0, 0, .2);
        width: clamp(400px, 75vw, 600px);
        padding: 2rem;
        border-radius: 12px;
        margin: 2vw;
        animation: zoomIn .5s ease forwards;

        @keyframes zoomIn {
            0% {
                opacity: 0;
                transform: scale(.5);
            }

            50% {
                opacity: 1;
                transform: scale(1.1);
            }

            100% {
                transform: scale(1);
            }
        }

        &[data-active='true'] {
            display: flex;
        }

        div {
            width: 100%;
        }

        h1 {
            font-size: 30px;

            span {
                font-size: 1rem;
                font-weight: 200;
            }
        }

        h2 {
            font-size: 1rem;
        }

        &.submitted {
            iconify-icon {
                font-size: 40px;
            }

            p {
                font-size: 1rem;
            }
        }

        label {
            font-size: 16px;
            display: block;
            color: rgb(144, 159, 201);
            line-height: 150%;

            &[required] {
                color: globals.$mainBlue;
            }

            span {
                font-size: .75rem;
                opacity: .75;
            }
        }

        input,
        select,
        textarea {
            // all: unset;
            display: flex;
            width: 100%;
            font-size: 1.1rem;
            font-weight: 300;
            padding: 3px 8px;
            color: globals.$mainBlue;
            background-color: transparent;
            border: 1px solid rgb(164, 174, 202);
            border-radius: 5px;
        }

        select {
            background-color: unset;
        }

        .card-row {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            .card-field {
                display: flex;
                flex-direction: column;
                flex-basis: 200px;
                flex-grow: 1;
                flex-shrink: 0;
                padding: 5px;
                min-width: 200px;
            }
        }

        .counter {
            position: absolute;
            top: 0;
            right: 0;
            transform: translateX(-100%);
            font-size: 14px;
            margin: 0;
        }

        select option {
            background-color: rgba(255, 255, 255, .9);
        }

        button {
            display: block;
            margin-top: 1rem;
            margin-left: 8px;
        }
    }

}

#Application-Form {
    display: flex;
    justify-content: center;

    input,
    label {
        font-size: 1rem;
    }

    label {
        line-height: 110%;
    }
}

.language-box {
    display: flex;
    align-items: center;

    p {
        margin: 0;
        font-size: 1.2rem;
    }

    .form-check {
        margin: 0 8px;
        padding: 0;

        input {
            margin: 0;
            padding: 0;
        }
    }
}