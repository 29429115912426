@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600&display=swap');

// #region === Variables ===
$scrollMargin: 79px;
$topHeaderSize: 40px;
// Colors
$mainBlue: #005681;
$satBlue: #2776CB;
$babyBlue: #C9F1FF;
$vividBlue: #1abfd4;
$greenie: #2d8850;
$lightGreen: #59c593;
$mygradient: linear-gradient(to left, $greenie, $lightGreen, $vividBlue, $mainBlue);
$blueGradient: linear-gradient(to top right, $mainBlue, $vividBlue);
$blueGradientShift: linear-gradient(to bottom left, $mainBlue, $vividBlue);
$blueFancyGradient: linear-gradient(to right, #30387e, #0199C4);
$pinkGradient: linear-gradient(to top right, rgb(221, 98, 117), rgb(230, 89, 159));
$iron: #fefefe;
// #endregion Variables