@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600&display=swap");
#Service-Hero {
  text-align: left;
  display: flex;
  position: relative;
  align-items: center;
  height: clamp(25rem, 40vh, 30rem);
  width: 100vw;
  background-position: bottom;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}
#Service-Hero h1,
#Service-Hero h2,
#Service-Hero p {
  color: white !important;
  text-shadow: 2px 2px 2px rgba(128, 128, 128, 0.719);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  margin: 0;
}
#Service-Hero h1 {
  font-size: 3.5rem;
}
#Service-Hero h1 .iconify.icon {
  font-size: 90%;
  transform: translateY(-12%);
}
#Service-Hero h2 {
  font-size: 1.75rem;
}
#Service-Hero a {
  font-weight: 400;
}
#Service-Hero[data-service=residential] {
  background-image: linear-gradient(to top, rgba(0, 86, 129, 0.9), rgba(58, 167, 182, 0.9)), url("../../img/backgrounds/service-backgrounds/s-house.webp");
}
#Service-Hero[data-service=rentals] {
  background-image: linear-gradient(to top, rgba(0, 86, 129, 0.9), rgba(58, 167, 182, 0.9)), url("../../img/backgrounds/service-backgrounds/rental-apartment.jpg");
}
#Service-Hero[data-service=commercial] {
  background-image: linear-gradient(to top, rgba(0, 86, 129, 0.9), rgba(58, 167, 182, 0.9)), url("../../img/backgrounds/service-backgrounds/commercial.webp");
}
#Service-Hero[data-service=biohazard] {
  background-image: linear-gradient(to top, rgba(0, 86, 129, 0.9), rgba(58, 167, 182, 0.9)), url("../../img/backgrounds/service-backgrounds/biohazard-cleaning.jpg");
}
#Service-Hero[data-service=additional] {
  background-image: linear-gradient(to top, rgba(0, 86, 129, 0.9), rgba(58, 167, 182, 0.9)), url("../../img/backgrounds/service-backgrounds/additional.jpg");
}

#Service {
  padding-top: 3rem;
}
#Service .container {
  text-align: left;
}
#Service .container .title-box {
  display: flex;
}
#Service .container .title-box h1,
#Service .container .title-box .iconify {
  font-size: 40px;
}
#Service .container .title-box .iconify {
  margin-right: 10px;
}
#Service .container .desc {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
#Service .container .desc .cta {
  margin-left: 5%;
}
#Service .container .desc .cta .SPK-CTA {
  padding: 0.45rem 1em;
}
#Service .container .desc .cta a {
  line-height: unset;
}
#Service .container .desc h2 {
  font-size: 1.2em;
  line-height: 110%;
}
#Service .container .desc p,
#Service .container .desc h3 {
  margin: 3% 5% 3% 0;
  font-size: 19px;
  font-weight: 400;
  line-height: 1.3;
}
#Service .container .desc a {
  line-height: 300%;
}
#Service .container .desc img {
  width: 100%;
  border-radius: 20px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
}
#Service .container .desc ul {
  position: relative;
  list-style: none;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.5px;
}
#Service .container .desc ul li::before {
  content: "✓";
  font-size: 1.25em;
  position: absolute;
  left: 0;
  color: limegreen;
}
#Service .container .checklist {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  text-align: center;
  align-items: center;
  margin-top: 80px;
  padding: 0;
}
#Service .container .checklist.green li,
#Service .container .checklist.green p {
  color: green;
}
#Service .container .checklist li {
  list-style: none;
  color: #005681;
  padding: 14px;
  margin: 10px;
  border-radius: 4px;
}
#Service .container .checklist li iconify-icon {
  font-size: 1.75em;
}
#Service .container .checklist li p {
  font-size: 18px;
}

#Packages {
  padding-bottom: 4vh;
}
#Packages .container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-basis: auto;
}
#Packages .package-card {
  position: relative;
  cursor: default;
  --card-border-radius: 20px;
  width: 30%;
  min-width: 300px;
  max-width: 400px;
  height: -moz-max-content;
  height: max-content;
  box-shadow: 0 0 20px silver;
  border-radius: var(--card-border-radius);
  margin: 20px auto;
  padding-bottom: 20px;
}
#Packages .package-card:hover {
  transition: transform 0.2s;
  transform: scale(1.03);
}
#Packages .package-card .head {
  background-color: #005681;
  color: whitesmoke;
  padding: 5% 0;
  height: -moz-min-content;
  height: min-content;
  border-top-right-radius: var(--card-border-radius);
  border-top-left-radius: var(--card-border-radius);
}
#Packages .package-card .head .decorative-line {
  background-color: whitesmoke;
  height: 3px;
}
#Packages .package-card .head h2 {
  letter-spacing: 0.5px;
  margin: 0 5%;
}
#Packages .package-card .head h3 {
  font-size: 16px;
  margin-top: 15px;
  line-height: 75%;
  letter-spacing: 1.5px;
  font-weight: 300;
}
#Packages .package-card .head p {
  color: whitesmoke;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
}
#Packages .package-card .container h2 {
  font-size: 1.4rem;
  line-height: 1;
  margin: 0;
  margin-top: 1rem;
}
#Packages .package-card .popular {
  position: absolute;
  min-width: 100%;
  background: linear-gradient(to left, rgb(165, 126, 27), rgb(241, 210, 31));
  padding: 1% 0;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 95% 50%, 100% 100%, 0% 100%, 5% 50%);
          clip-path: polygon(0% 0%, 100% 0%, 95% 50%, 100% 100%, 0% 100%, 5% 50%);
  transform: translateY(-50%) scale(111%);
}
#Packages .package-card .popular p {
  color: white;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0;
}
#Packages .package-card .iconify {
  font-size: 50px;
}
#Packages .package-card ul {
  margin: 10% 15%;
  padding: 0;
}
#Packages .package-card ul li {
  font-size: 18px;
  line-height: 1;
  text-align: left;
  margin-bottom: 10%;
}
#Packages .package-card .discount,
#Packages .package-card .discount .iconify {
  font-size: 20px;
  color: #2d8850;
}
#Packages .package-card .discount {
  width: -moz-max-content;
  width: max-content;
  margin: 0 auto;
  padding: 10px 15px;
}
#Packages .package-card .time .times p {
  font-size: 16px;
}
#Packages .package-list {
  list-style: disc;
}
#Packages .package-list li {
  font-size: 18px;
  text-align: left;
}

.info-card {
  display: flex;
  flex-direction: column;
  width: 31%;
  background-color: #005681;
  border-radius: 8px;
  box-shadow: 6px 6px 18px rgba(0, 0, 0, 0.6);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  transition: 0.25s;
  margin-bottom: 30px;
}
.info-card:hover {
  transform: scale(1.05);
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.2);
}
.info-card * {
  padding: 10px;
  color: white;
  margin: 0;
}
.info-card .title {
  font-size: 26px;
  background-color: goldenrod;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.3);
  text-align: center;
}
.info-card .content {
  display: flex;
  align-items: center;
  padding: 5% 0;
}
.info-card .content img {
  border-radius: 100%;
  border: 4px solid #1abfd4;
  box-shadow: 2px 2px 10px rgb(0, 0, 0);
  margin: 10px;
  padding: 0;
}
.info-card .btm-lbl {
  background-color: #1b4256;
  margin: 0;
  padding: 15px 12px;
  opacity: 0.8;
  font-size: 16px;
  border-radius: 8px;
  text-align: center;
}
.info-card .btm-lbl .iconify-inline {
  font-size: 30px;
  padding: 0;
}
.info-card .btm-lbl .iconify-inline.warning * {
  color: rgb(255, 48, 48);
}

.contact.container {
  text-align: center;
  padding: 12vh 0;
}

@media screen and (max-width: 1200px) {
  .info-card {
    width: 48%;
  }
}
@media screen and (max-width: 991px) {
  #Service .container .desc {
    grid-template-columns: 1fr;
  }
  #Service .container .desc .cta {
    margin: 5% 0;
  }
  #Service .container .desc img {
    width: 100%;
    max-width: unset;
    -webkit-clip-path: none;
            clip-path: none;
  }
  #Service .container .checklist {
    grid-template-columns: 1fr 1fr;
  }
  #Packages .container {
    flex-direction: column;
  }
  #Packages .container .package-card {
    width: 100%;
  }
  #Packages .container .package-card ul li {
    margin-bottom: 3%;
  }
}
@media screen and (max-width: 768px) {
  .info-card {
    width: 95%;
  }
}
@media screen and (max-width: 575px) {
  #Service .container .checklist {
    grid-template-columns: 1fr;
  }
  .info-card {
    width: 95%;
    margin: 10px auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
  .info-card:hover {
    transform: none;
  }
  #Packages .container .package-card .popular {
    position: unset;
    transform: scale(1);
    -webkit-clip-path: none;
            clip-path: none;
  }
}/*# sourceMappingURL=services.css.map */