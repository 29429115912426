@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

#Gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
}

#Wall {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    padding: 1rem 2vw;

    img {
        width: 100%;
        max-width: 600px;
        box-shadow: 3px 3px 15px black;
        transition: .15s;
        border-radius: 1.5%;

        &:hover {
            box-shadow: 3px 3px 20px black;
            filter: grayscale(0%);
            transform: scale(1.01);
        }
    }
}

.contact {
    text-align: center;
    padding: 15vh 0;
}

@media screen and (max-width: 1200px) {
    #Wall {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 900px) {
    #Wall {
        grid-template-columns: 1fr;
    }
}