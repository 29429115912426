@use '../../styles/globals';

#Before-After {
    --default-slider-pos: 25%;

    .contain {
        position: relative;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;

        .ba-item {
            position: relative;
            align-items: center;
            height: 35vh;
            min-height: 200px;
            width: 95%;
            max-width: 700px;
            margin: 20px;
            box-shadow: 0 0 15px dimgray;
            opacity: .85;
            transition: .15s;
            border-radius: 10px;

            .before,
            .after {
                object-fit: cover;
                object-position: center;
                position: absolute;
            }

            .after {
                z-index: 1;
            }

            input {
                position: absolute;
                z-index: 3;
                height: 100%;
                width: calc(100% + 80px);
                appearance: none;
                background: none;
                cursor: col-resize;
                animation: flash .4s infinite alternate;

                &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    border: none;
                    height: 80px;
                    width: 80px;
                    background: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="80px" height="80px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 256 256"%3E%3Cpath fill="white" d="M134 40v176a6 6 0 0 1-12 0V40a6 6 0 0 1 12 0Zm-38 82H30.5l21.7-21.8a5.9 5.9 0 0 0-8.4-8.4l-32 32a5.8 5.8 0 0 0 0 8.4l32 32a5.9 5.9 0 0 0 8.4-8.4L30.5 134H96a6 6 0 0 0 0-12Zm148.2 1.8l-32-32a5.9 5.9 0 0 0-8.4 8.4l21.7 21.8H160a6 6 0 0 0 0 12h65.5l-21.7 21.8a5.9 5.9 0 1 0 8.4 8.4l32-32a5.8 5.8 0 0 0 0-8.4Z"%2F%3E%3C%2Fsvg%3E');
                    background-size: cover;
                }
            }

            .strip {
                display: block;
                position: absolute;
                left: var(--default-slider-pos);
                transform: translateX(-50%);
                z-index: 2;
                height: 100%;
                width: 2px;
                background-color: white;
                box-shadow: 0 0 10px globals.$vividBlue;
                animation: flash .4s infinite alternate;
            }

            &:hover {
                opacity: 1;
                transform: scale(1.02);
            }
        }
    }

    @keyframes flash {
        from {
            opacity: .4;
        }

        to {
            opacity: 1;
        }
    }
}

@media only screen and (max-width: 1199px) {
    #Before-After .contain {
        grid-template-columns: 1fr;
        justify-items: center;

        .ba-item {
            width: 100%;
        }
    }
}