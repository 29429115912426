@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600&display=swap");
#Contact-Form {
  display: flex;
  justify-content: center;
  margin-bottom: 20vh;
}
#Contact-Form .form-card {
  display: none;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
  width: clamp(400px, 75vw, 600px);
  padding: 2rem;
  border-radius: 12px;
  margin: 2vw;
  animation: zoomIn 0.5s ease forwards;
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
#Contact-Form .form-card[data-active=true] {
  display: flex;
}
#Contact-Form .form-card div {
  width: 100%;
}
#Contact-Form .form-card h1 {
  font-size: 30px;
}
#Contact-Form .form-card h1 span {
  font-size: 1rem;
  font-weight: 200;
}
#Contact-Form .form-card h2 {
  font-size: 1rem;
}
#Contact-Form .form-card.submitted iconify-icon {
  font-size: 40px;
}
#Contact-Form .form-card.submitted p {
  font-size: 1rem;
}
#Contact-Form .form-card label {
  font-size: 16px;
  display: block;
  color: rgb(144, 159, 201);
  line-height: 150%;
}
#Contact-Form .form-card label[required] {
  color: #005681;
}
#Contact-Form .form-card label span {
  font-size: 0.75rem;
  opacity: 0.75;
}
#Contact-Form .form-card input,
#Contact-Form .form-card select,
#Contact-Form .form-card textarea {
  display: flex;
  width: 100%;
  font-size: 1.1rem;
  font-weight: 300;
  padding: 3px 8px;
  color: #005681;
  background-color: transparent;
  border: 1px solid rgb(164, 174, 202);
  border-radius: 5px;
}
#Contact-Form .form-card select {
  background-color: unset;
}
#Contact-Form .form-card .card-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
#Contact-Form .form-card .card-row .card-field {
  display: flex;
  flex-direction: column;
  flex-basis: 200px;
  flex-grow: 1;
  flex-shrink: 0;
  padding: 5px;
  min-width: 200px;
}
#Contact-Form .form-card .counter {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(-100%);
  font-size: 14px;
  margin: 0;
}
#Contact-Form .form-card select option {
  background-color: rgba(255, 255, 255, 0.9);
}
#Contact-Form .form-card button {
  display: block;
  margin-top: 1rem;
  margin-left: 8px;
}

#Application-Form {
  display: flex;
  justify-content: center;
}
#Application-Form input,
#Application-Form label {
  font-size: 1rem;
}
#Application-Form label {
  line-height: 110%;
}

.language-box {
  display: flex;
  align-items: center;
}
.language-box p {
  margin: 0;
  font-size: 1.2rem;
}
.language-box .form-check {
  margin: 0 8px;
  padding: 0;
}
.language-box .form-check input {
  margin: 0;
  padding: 0;
}/*# sourceMappingURL=contact.css.map */