@use '../globals';

#Service-Hero {
    text-align: left;
    display: flex;
    position: relative;
    align-items: center;
    height: clamp(25rem, 40vh, 30rem);
    width: 100vw;
    background-position: bottom;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;

    h1,
    h2,
    p {
        color: white !important;
        text-shadow: 2px 2px 2px rgba(128, 128, 128, 0.719);
        user-select: none;
        margin: 0;
    }

    h1 {
        font-size: 3.5rem;

        .iconify.icon {
            font-size: 90%;
            transform: translateY(-12%);
        }
    }

    h2 {
        font-size: 1.75rem;
    }

    a {
        font-weight: 400;
    }

    &[data-service='residential'] {
        background-image: linear-gradient(to top, rgba(0, 86, 129, 0.9), rgba(58, 167, 182, 0.9)), url('../../img/backgrounds/service-backgrounds/s-house.webp');
    }

    &[data-service='rentals'] {
        background-image: linear-gradient(to top, rgba(0, 86, 129, 0.9), rgba(58, 167, 182, 0.9)), url('../../img/backgrounds/service-backgrounds/rental-apartment.jpg');
    }

    &[data-service='commercial'] {
        background-image: linear-gradient(to top, rgba(0, 86, 129, 0.9), rgba(58, 167, 182, 0.9)), url('../../img/backgrounds/service-backgrounds/commercial.webp');
    }

    &[data-service='biohazard'] {
        background-image: linear-gradient(to top, rgba(0, 86, 129, 0.9), rgba(58, 167, 182, 0.9)), url('../../img/backgrounds/service-backgrounds/biohazard-cleaning.jpg');
    }

    &[data-service='additional'] {
        background-image: linear-gradient(to top, rgba(0, 86, 129, 0.9), rgba(58, 167, 182, 0.9)), url('../../img/backgrounds/service-backgrounds/additional.jpg');
    }
}

#Service {
    padding-top: 3rem;

    .container {
        text-align: left;

        .title-box {
            display: flex;

            h1,
            .iconify {
                font-size: 40px;
            }

            .iconify {
                margin-right: 10px;
            }
        }


        .desc {
            display: grid;
            grid-template-columns: 1fr 1fr;

            .cta {
                margin-left: 5%;

                .SPK-CTA {
                    padding: .45rem 1em;
                }

                a {
                    line-height: unset;
                }
            }

            h2 {
                font-size: 1.2em;
                line-height: 110%;
            }

            p,
            h3 {
                margin: 3% 5% 3% 0;
                font-size: 19px;
                font-weight: 400;
                line-height: 1.3;
            }

            a {
                line-height: 300%;
            }

            img {
                width: 100%;
                border-radius: 20px;
                box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
            }

            ul {
                position: relative;
                list-style: none;
                font-size: 16px;
                font-weight: 300;
                letter-spacing: .5px;

                li {
                    &::before {
                        content: '✓';
                        font-size: 1.25em;
                        position: absolute;
                        left: 0;
                        color: limegreen
                    }
                }

            }
        }

        .checklist {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            text-align: center;
            align-items: center;
            margin-top: 80px;
            padding: 0;

            &.green {

                li,
                p {
                    color: green;
                }
            }

            li {
                list-style: none;
                color: globals.$mainBlue;
                padding: 14px;
                margin: 10px;
                border-radius: 4px;

                iconify-icon {
                    font-size: 1.75em;
                }

                p {
                    font-size: 18px;
                }
            }
        }

    }
}

#Packages {
    padding-bottom: 4vh;

    .container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-basis: auto;
    }

    .package-card {
        position: relative;
        cursor: default;
        --card-border-radius: 20px;
        width: 30%;
        min-width: 300px;
        max-width: 400px;
        height: max-content;
        box-shadow: 0 0 20px silver;
        border-radius: var(--card-border-radius);
        margin: 20px auto;
        padding-bottom: 20px;

        &:hover {
            transition: transform .2s;
            transform: scale(1.03);
        }

        .head {
            background-color: globals.$mainBlue;
            color: whitesmoke;
            padding: 5% 0;
            height: min-content;
            border-top-right-radius: var(--card-border-radius);
            border-top-left-radius: var(--card-border-radius);

            .decorative-line {
                background-color: whitesmoke;
                height: 3px;
            }

            h2 {
                letter-spacing: 0.5px;
                margin: 0 5%;
            }

            h3 {
                font-size: 16px;
                margin-top: 15px;
                line-height: 75%;
                letter-spacing: 1.5px;
                font-weight: 300;
            }

            p {
                color: whitesmoke;
                font-size: 18px;
                font-weight: 300;
                letter-spacing: 2px;
                text-transform: uppercase;
            }
        }

        .container {
            h2 {
                font-size: 1.4rem;
                line-height: 1;
                margin: 0;
                margin-top: 1rem;
            }
        }

        .popular {
            position: absolute;
            min-width: 100%;
            background: linear-gradient(to left, rgb(165, 126, 27), rgb(241, 210, 31));
            padding: 1% 0;
            clip-path: polygon(0%0%, 100%0%, 95%50%, 100%100%, 0%100%, 5%50%);
            transform: translateY(-50%) scale(111%);

            p {
                color: white;
                font-size: 20px;
                font-weight: 400;
                letter-spacing: 1px;
                margin: 0;
            }
        }

        .iconify {
            font-size: 50px;
        }

        ul {
            margin: 10% 15%;
            padding: 0;

            li {
                font-size: 18px;
                line-height: 1.0;
                text-align: left;
                margin-bottom: 10%;
            }
        }

        .discount,
        .discount .iconify {
            font-size: 20px;
            color: globals.$greenie;
        }

        .discount {
            width: max-content;
            margin: 0 auto;
            padding: 10px 15px;
        }


        .time {
            .times {

                p {
                    font-size: 16px;
                }
            }

        }
    }

    .package-list {
        list-style: disc;

        li {
            font-size: 18px;
            text-align: left;
        }
    }
}

.info-card {
    $cardBorderRadius: 8px;
    display: flex;
    flex-direction: column;
    width: 31%;
    background-color: globals.$mainBlue;
    border-radius: $cardBorderRadius;
    box-shadow: 6px 6px 18px rgba(0, 0, 0, 0.6);
    user-select: none;
    transition: .25s;
    margin-bottom: 30px;

    &:hover {
        transform: scale(1.05);
        box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.2);
    }

    * {
        padding: 10px;
        color: white;
        margin: 0;
    }

    .title {
        font-size: 26px;
        background-color: goldenrod;
        padding: 10px;
        border-radius: $cardBorderRadius;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
        text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.3);
        text-align: center;
    }

    .content {
        display: flex;
        align-items: center;
        padding: 5% 0;

        img {
            border-radius: 100%;
            border: 4px solid globals.$vividBlue;
            box-shadow: 2px 2px 10px rgba(0, 0, 0.3);
            margin: 10px;
            padding: 0;
        }
    }

    .btm-lbl {
        background-color: #1b4256;
        margin: 0;
        padding: 15px 12px;
        opacity: .8;
        font-size: 16px;
        border-radius: $cardBorderRadius;
        text-align: center;

        .iconify-inline {
            font-size: 30px;
            padding: 0;

            &.warning {
                * {
                    color: rgb(255, 48, 48);
                }
            }
        }
    }
}

.contact.container {
    text-align: center;
    padding: 12vh 0;
}

// MEDIA QUERIES

@media screen and (max-width:1200px) {
    .info-card {
        width: 48%;
    }
}

@media screen and (max-width: 991px) {
    #Service .container {
        .desc {

            grid-template-columns: 1fr;

            .cta {
                margin: 5% 0;
            }

            img {
                width: 100%;
                max-width: unset;
                clip-path: none;
            }
        }

        .checklist {
            grid-template-columns: 1fr 1fr;
        }
    }

    #Packages {
        .container {
            flex-direction: column;

            .package-card {
                width: 100%;

                ul li {
                    margin-bottom: 3%;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .info-card {
        width: 95%;
    }
}

@media screen and (max-width: 575px) {
    #Service .container .checklist {
        grid-template-columns: 1fr;
    }

    .info-card {
        width: 95%;
        margin: 10px auto;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);

        &:hover {
            transform: none;
        }
    }

    #Packages {
        .container {
            .package-card {
                .popular {
                    position: unset;
                    transform: scale(1);
                    clip-path: none;
                }
            }
        }
    }
}