@use '../../styles/globals';

#Hero {
    // Keeps the absolute background within bounds
    position: relative;
    text-align: left;
    display: flex;
    align-items: center;
    height: clamp(35rem, 80vh, 50rem);
    width: 100vw;

    &::before {
        // Background Image
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.6;
        background-color: #E2F0FA;
        background-image: linear-gradient(to top, transparent 0%, transparent 92%, globals.$babyBlue 100%), url('../../img/backgrounds/Blue-Gloves-Heart.jpg');
        background-image: linear-gradient(to top, transparent 0%, transparent 92%, globals.$babyBlue 100%), url('../../img/backgrounds/Blue-Gloves-Heart.webp');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right;
    }

    h2,
    p {
        color: globals.$mainBlue !important;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.123);
        user-select: none;
    }

    h1 {
        margin: 0;
    }

    h2 {
        font-size: clamp(2rem, 8vw, 3.5rem);
        line-height: 1;
    }

    h3 {
        color: hotpink;

        span {
            color: globals.$mainBlue;
            font-weight: 300;
        }
    }

    p {
        font-weight: 300;
        font-size: 1.4rem;
    }

    a {
        font-weight: 400;
        font-size: 1.1rem;
        padding: 1.35em 2em;
        border: none;
        background: globals.$pinkGradient;
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);

        &:hover,
        &:focus {
            background: none;
            color: hotpink;
            outline: 2px solid hotpink;
        }
    }

    #All-in-one {
        font-size: 80%;
    }
}

#Banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5%;
    background-color: #fefefe;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.4);
    border-radius: 1000px;
    min-height: 100px;
    transform: translateY(-50%);

    .banner-box {
        max-height: 80px;
        width: 30%;
        text-align: center;

        .iconify {
            font-size: 28px;
        }

        .bbb {
            height: 55px;
            width: 30px;
            border-radius: 8px;
        }

        p {
            font-size: 16px;
            display: inline;
        }
    }

    .stat {
        font-size: 20px;
        margin: 0;
        line-height: 1.25;
        font-weight: 400;

        #Banner-count {
            font-size: 24px;
        }
    }
}

#About {
    display: flex;
    position: relative;
    z-index: 0;
    flex-direction: column;
    padding: max(3vh, 50px) 0;
    color: white;
    background: linear-gradient(to top, rgba(48, 55, 71, 0.9), rgba(16, 38, 53, 0.952)), url("../../img/backgrounds/service-backgrounds/wiping.jpg") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    user-select: none;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

    .text-box {

        p,
        h1 {
            color: white;
            font-weight: 300;
            font-size: 18px;

            &:first-child {
                // Heading
                font-weight: bold;
                font-size: 26px;
                line-height: 1.25;

                span {
                    // TM
                    font-size: 75%;
                    font-weight: 200;
                }
            }
        }

        .check {
            font-size: 18px;
            color: globals.$babyBlue;

            span {
                display: flex;
                font-weight: bold;
                font-size: 1.25em;
                margin-bottom: 1%;

                &::before {
                    content: '\2714';
                    display: inline-block;
                    color: hotpink;
                    height: 10px;
                    width: 10px;
                    margin-right: 15px;
                }
            }

        }
    }

    .line {
        height: 2px;
        width: 75%;
        background-color: white;
        opacity: .5;
        margin: 5% auto;
    }

    .perks {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .perk {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 20px;
            width: max-content;
            max-width: 150px;

            .iconify {
                font-size: 60px;
                color: white;
                margin: 10px;
            }

            p {
                color: globals.$babyBlue;
                font-size: 16px;
                margin: 0;
                line-height: 18px;
                font-weight: 300;
            }
        }
    }
}

#Satisfaction {
    padding: 12vh 0;
    margin-bottom: 8vh;
    background: linear-gradient(to bottom right, #00293d, #2179a5, #00293d);
    color: white;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);

    h2 {
        font-size: 2.5rem;
    }

    p {
        font-weight: bold;
        color: white;
        font-size: 1.75rem;
        line-height: 1.25;
    }

    .ribbon {
        margin: 1rem 0;
        width: min(240px, 80%);
        filter: drop-shadow(4px 4px 1rem rgba(0, 0, 0, 0.4));
        padding: 1rem;
    }
}

#Services {
    max-width: 1500px;
    margin: 0 auto;

    .service-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .service-button {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 30vh;
            min-height: 350px;
            color: white;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            transition: .2s;

            &:hover {
                background-position: top;

                .icon {
                    opacity: 1;
                    font-size: 60px;
                    margin-bottom: 10px;
                }

                p {
                    opacity: 1;
                }
            }

            .icon {
                font-size: 50px;
                opacity: .8;
                transition: .2s;
            }

            .blink {
                display: none;
                font-size: 35px;
            }

            h2 {
                font-size: 40px;
            }

            p {
                color: white;
                opacity: .8;
                transition: .2s;
            }

            $service-border-curve: 15px;
            $service-color-opacity: 0.85;

            &[data-service='residential'] {
                background-image: linear-gradient(to top right, rgba(0, 86, 129, $service-color-opacity), rgba(26, 190, 212, $service-color-opacity)), url('../../img/backgrounds/service-backgrounds/residential.webp');
                border-top-left-radius: $service-border-curve;
            }

            &[data-service='commercial'] {
                background-image: linear-gradient(to top right, rgba(1, 104, 56, $service-color-opacity), rgba(31, 209, 141, $service-color-opacity)), url('../../img/backgrounds/service-backgrounds/commercial.webp');
                border-top-right-radius: $service-border-curve;
            }

            &[data-service='biohazard'] {
                background-image: linear-gradient(to top right, rgba(100, 36, 25, $service-color-opacity), rgba(199, 70, 53, $service-color-opacity)), url('../../img/backgrounds/service-backgrounds/biohazard-cleaning.jpg');
                border-bottom-left-radius: $service-border-curve;
            }

            &[data-service='rentals'] {
                background-image: linear-gradient(to top right, rgba(100, 36, 25, $service-color-opacity), rgba(199, 70, 53, $service-color-opacity)), url('../../img/backgrounds/service-backgrounds/rental-apartment.jpg');
                border-bottom-left-radius: $service-border-curve;
            }

            &[data-service='additional'] {
                background-image: linear-gradient(to top right, rgba(129, 0, 90, $service-color-opacity), rgba(212, 26, 150, $service-color-opacity)), url('../../img/backgrounds/service-backgrounds/additional.jpg');
                border-bottom-right-radius: $service-border-curve;
            }
        }
    }
}

#Video {
    margin: 5rem 0;
    padding: 0 2vw;

    video {
        border-radius: 1rem;
        box-shadow: .2rem .2rem 2rem rgba(0, 0, 0, 0.5);
        width: min(100%, 1000px);
        padding: 0;
    }
}

#Before-After {
    --default-slider-pos: 25%;

    padding-bottom: 10vh;

    .contain {
        position: relative;
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .ba-item {
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            height: 35vh;
            min-height: 200px;
            margin: 20px;
            box-shadow: 0 0 15px dimgray;
            opacity: .85;
            transition: .15s;

            .before,
            .after {
                position: absolute;
                height: 100%;
                width: 100%;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }

            .after {
                z-index: 1;
            }

            input {
                position: absolute;
                z-index: 3;
                height: 100%;
                width: calc(100% + 80px);
                appearance: none;
                background: none;
                cursor: col-resize;
                animation: flash .4s infinite alternate;

                &::-webkit-slider-thumb,
                &::-moz-range-thumb {
                    -webkit-appearance: none;
                    border: none;
                    height: 80px;
                    width: 80px;
                    background: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="80px" height="80px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 256 256"%3E%3Cpath fill="white" d="M134 40v176a6 6 0 0 1-12 0V40a6 6 0 0 1 12 0Zm-38 82H30.5l21.7-21.8a5.9 5.9 0 0 0-8.4-8.4l-32 32a5.8 5.8 0 0 0 0 8.4l32 32a5.9 5.9 0 0 0 8.4-8.4L30.5 134H96a6 6 0 0 0 0-12Zm148.2 1.8l-32-32a5.9 5.9 0 0 0-8.4 8.4l21.7 21.8H160a6 6 0 0 0 0 12h65.5l-21.7 21.8a5.9 5.9 0 1 0 8.4 8.4l32-32a5.8 5.8 0 0 0 0-8.4Z"%2F%3E%3C%2Fsvg%3E');
                    background-size: cover;
                }
            }

            .strip {
                display: block;
                position: absolute;
                left: var(--default-slider-pos);
                transform: translateX(-50%);
                z-index: 2;
                height: 100%;
                width: 2px;
                background-color: white;
                box-shadow: 0 0 10px globals.$vividBlue;
                animation: flash .4s infinite alternate;
            }

            &:hover {
                opacity: 1;
                transform: scale(1.02);
            }
        }
    }

    @keyframes flash {
        from {
            opacity: .4;
        }

        to {
            opacity: 1;
        }
    }
}

#FAQ {
    background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.85)), url('../../img/backgrounds/Blue-Gloves-Heart.jpg');
    background-position: top right;
    background-attachment: fixed;
    background-repeat: repeat;
    background-size: cover;
    padding: 15vh 0;

    .accordion-item {
        background-color: rgba(255, 255, 255, 0.5);
        margin: 10px auto;
        max-width: 1000px;

        &:focus {
            outline: 0;
        }

        &:nth-of-type(2n) {
            background-color: #1d618331;

            .accordion-button .iconify {
                color: globals.$mainBlue;
            }
        }

    }


    .accordion-button {
        --accordion-font-size: 20px;
        font-size: var(--accordion-font-size);
        background-color: rgba(255, 255, 255, 0.5);

        p {
            margin: 0;
            font-size: var(--accordion-font-size);
        }

        .iconify {
            margin-right: 20px;
            font-size: var(--accordion-font-size);
            color: hotpink;
        }

        &:focus {
            box-shadow: none;
        }
    }

    .accordion-body {
        color: globals.$mainBlue;
        text-align: start;

        .iconify {
            margin-right: 10px;
        }

        p {
            display: inline;
            font-size: 18px;
        }
    }
}

#Contact {
    background: linear-gradient(to bottom, globals.$vividBlue, globals.$mainBlue);
    padding: 200px 0;

    h1 {
        color: white;
        margin: 0 20px;
    }

    .alert {
        display: none;
        color: lightgreen;
    }

    a {
        font-size: 18px;
        background-color: white;
        color: globals.$mainBlue;
        padding: .9rem 1.9rem;
        border-radius: 100px;
        margin-top: 20px;
        border: 2px solid white;
        transition: .4s;
        margin: 20px 0;

        &:hover {
            background-color: transparent;
            color: white;
            box-shadow: 0 0 10px white;
        }
    }
}

#Meet-The-Team {
    padding: 8vh 0;

    .container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .TeamMember {
            transition: transform .15s ease;
            user-select: none;
            padding: 5px;
            margin: 12px max(15px, 2vw);

            &:hover {
                transform: scale(1.05);
            }

            img {
                border-radius: 100%;
                box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
                width: 175px;
                height: 175px;
                margin-bottom: 5%;
            }

            h1 {
                font-size: clamp(20px, 5vw, 26px);
                margin: 0;
                line-height: 100%;
                margin-bottom: 8px;
                font-weight: 500;
            }

            h2 {
                font-size: clamp(16px, 4vw, 18px);
                font-weight: 300;
                margin: 0;
                line-height: 100%;

                &:nth-of-type(2) {
                    margin-top: 5px;
                }
            }

            .website {
                font-size: 16px;
                font-weight: 300;
            }

            .socials {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 10px;

                a {
                    margin: 0 7px;
                    color: globals.$mainBlue;

                    &:hover {
                        color: globals.$vividBlue;
                    }
                }
            }

        }
    }
}

// #region === MEDIA QUERIES ===
@media screen and (max-width: 1199px) {

    #Hero {
        &:before {
            opacity: .6;
        }
    }

    #Additional-Services {
        .service-grid {
            grid-template-columns: 1fr;
        }
    }

    #Before-After {
        .contain {
            grid-template-columns: 1fr;
        }
    }
}

@media screen and (max-width: 991px) {
    #Hero {
        &:before {
            background-position: top;
        }
    }

    #Banner {
        flex-direction: column;

        .banner-box {
            width: max-content;
            margin: 20px 0;
        }
    }

    #Services,
    #Additional-Services {
        .service-grid {
            grid-template-columns: 1fr;

            .service-button {

                &[data-service='residential'],
                &[data-service='commercial'],
                &[data-service='biohazard'],
                &[data-service='rentals'],
                &[data-service='additional'] {
                    border-radius: unset;
                }

                .blink {
                    display: block;
                    animation: blink .5s alternate infinite;
                }
            }
        }
    }

}

@media screen and (max-width: 575px) {
    #Hero #All-in-one {
        font-size: 60%;
        font-weight: 700;
    }

    #Banner {
        border-radius: 0;
        transform: unset;
    }

    #Services {
        .services-grid {
            grid-template-columns: 1fr;
        }
    }
}

// #endregion Media Queries