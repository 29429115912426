@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600&display=swap");
.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

html {
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  height: auto;
}
body.prevent-scroll,
html.prevent-scroll {
  overflow-y: hidden;
}
body::-webkit-scrollbar,
html::-webkit-scrollbar {
  all: unset;
  width: 14px;
}
body::-webkit-scrollbar-track,
html::-webkit-scrollbar-track {
  all: unset;
}
body::-webkit-scrollbar-thumb,
html::-webkit-scrollbar-thumb {
  all: unset;
  background-color: #1abfd4;
}

body {
  overflow-x: hidden;
}

body,
p {
  color: #005681;
  font: 400 1.5rem/1.8rem "Lexend", sans-serif;
}

.p-large {
  font: 400 1.125rem/1.75rem "Lexend", sans-serif;
}

.p-small {
  font: 400 0.875rem/1.5rem "Lexend", sans-serif;
}

section {
  text-align: center;
  scroll-margin-top: 79px;
  width: 100%;
  overflow: hidden;
}

h1 {
  font: 700 2.5rem/3.125rem "Lexend", sans-serif;
  letter-spacing: -0.2px;
}

h2 {
  font: 700 2rem/2.625rem "Lexend", sans-serif;
  letter-spacing: -0.2px;
}

h3 {
  font: 700 1.625rem/2.125rem "Lexend", sans-serif;
  letter-spacing: -0.2px;
}

h4 {
  font: 700 1.375rem/1.75rem "Lexend", sans-serif;
  letter-spacing: -0.1px;
}

h5 {
  font: 700 1.125rem/1.5rem "Lexend", sans-serif;
  letter-spacing: -0.1px;
}

h6 {
  font: 700 1rem/1.375rem "Lexend", sans-serif;
  letter-spacing: -0.1px;
}

a,
a:hover {
  text-decoration: none;
}

a.white {
  color: #fff;
}

.decorative-line {
  display: block;
  width: 5rem;
  height: 0.25rem;
  border-radius: 50px;
  background-color: #005681;
  margin: 16px auto;
}
.decorative-line.white {
  background-color: whitesmoke;
}

.SPK-CTA {
  display: inline-block;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 1;
  font-weight: 400;
  font-size: 1.1rem;
  padding: 0.75em 1.75em;
  border: none;
  border-radius: 99px;
  background: linear-gradient(to top right, #005681, #1abfd4);
  color: white;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}
.SPK-CTA:hover, .SPK-CTA:focus {
  color: #005681;
  background: none;
  box-shadow: 0 0 10px #1abfd4;
}
.SPK-CTA:hover::after, .SPK-CTA:focus::after {
  opacity: 1;
}

.btn-solid-reg {
  display: inline-block;
  padding: 1.1875rem 2.125rem 1.1875rem 2.125rem;
  border: 0.125rem solid #005681;
  border-radius: 2rem;
  background-color: #005681;
  color: #fff;
  font: 700 0.875rem/0 "Lexend", sans-serif;
  transition: all 0.2s;
}
.btn-solid-reg:hover {
  background-color: transparent;
  color: #005681;
}

.btn-solid-lg {
  display: inline-block;
  padding: 20px 35px;
  border: 0.125rem solid #005681;
  border-radius: 20rem;
  background-color: #005681;
  color: #fff;
  font: 700 0.875rem/0 "Lexend", sans-serif;
  transition: all 0.2s;
}
.btn-solid-lg:hover {
  background-color: transparent;
  color: #005681;
}

.btn-outline-reg {
  display: inline-block;
  padding: 1.1875rem 2.125rem 1.1875rem 2.125rem;
  border: 0.125rem solid #005681;
  border-radius: 2rem;
  background-color: transparent;
  color: #005681;
  font: 700 0.875rem/0 "Lexend", sans-serif;
  transition: all 0.2s;
}
.btn-outline-reg:hover {
  background-color: #005681;
  color: #fff;
}

.btn-outline-lg {
  display: inline-block;
  padding: 1.375rem 2.625rem 1.375rem 2.625rem;
  border: 0.125rem solid #005681;
  border-radius: 2rem;
  background-color: transparent;
  color: #005681;
  font: 700 0.875rem/0 "Lexend", sans-serif;
  transition: all 0.2s;
}
.btn-outline-lg:hover {
  background-color: #005681;
  color: #fff;
}

.btn-outline-sm {
  display: inline-block;
  padding: 0.875rem 1.5rem 0.875rem 1.5rem;
  border: 0.125rem solid #005681;
  border-radius: 2rem;
  background-color: transparent;
  color: #005681;
  font: 700 0.875rem/0 "Lexend", sans-serif;
  transition: all 0.2s;
}
.btn-outline-sm:hover {
  background-color: #005681;
  color: #fff;
}

.notFound {
  margin: 20vh auto;
}
.notFound p {
  font-size: 1rem;
}

.Policy {
  padding: 8vh 1vh;
  text-align: start;
}
.Policy *:not(h1) {
  text-align: left;
  font-size: 20px;
}
.Policy .logo {
  max-width: 100%;
}
.Policy .iconify {
  font-size: 5rem;
}
.Policy p,
.Policy a,
.Policy ol li,
.Policy .accordion {
  margin: 3vh auto;
  font-weight: 300;
}
.Policy p.title {
  font-weight: 400;
  text-transform: uppercase;
}
.Policy .contents-links a {
  display: block;
  margin: 0 0 5px 0;
}
.Policy .accordion-button,
.Policy .accordion-body {
  color: #005681;
}
.Policy .signature p {
  text-align: right;
  margin: 0 auto;
}

#Top-header {
  z-index: 901;
  height: 40px;
  width: 100%;
  background-color: #005681;
  background: linear-gradient(to right, #30387e, #0199C4);
  display: flex;
  justify-content: center;
  align-items: center;
}
#Top-header * {
  font-family: "Lexend";
  color: #fefefe;
  margin: 0;
}
#Top-header .container {
  display: flex;
  justify-content: space-between;
}
#Top-header .phone-box a {
  font-family: "Lexend", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
  font-size: 28px;
}
#Top-header .announcement {
  display: flex;
  justify-content: space-between;
}
#Top-header .announcement p {
  font-size: 16px;
  font-weight: 300;
}
#Top-header .announcement a {
  text-decoration: underline;
  padding: 10px 0;
}
#Top-header .announcement #HolidayIcon {
  position: relative;
  display: flex;
  padding: 3px;
  margin-right: 8px;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
}
#Top-header .announcement #HolidayIcon p {
  position: absolute;
  top: 0;
  background-color: white;
  border-radius: 99px;
  padding: 5px 10px;
  width: -moz-max-content;
  width: max-content;
  z-index: 999;
  color: #005681;
  font-weight: 500;
  font-size: 14px;
  opacity: 0;
  right: 50%;
  transition: 0.2s;
  scale: 0.8;
}
#Top-header .announcement #HolidayIcon:hover p {
  right: 110%;
  opacity: 1;
  scale: 1;
}
#Top-header .options-box {
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0.5px;
}
#Top-header .options-box a {
  margin-left: 30px;
  padding: 10px 0;
}

#Search {
  background-color: white;
}
#Search #Search-Bar {
  display: flex;
  position: relative;
  justify-content: space-between;
}
#Search #Search-Bar .socials-box {
  display: flex;
  align-items: center;
}
#Search #Search-Bar .socials-box a {
  margin-right: 1.4rem;
  padding-right: 5px;
  font-size: 1.2rem;
  color: #005681;
}
#Search #Search-Bar .socials-box a:hover, #Search #Search-Bar .socials-box a:focus {
  color: #1abfd4;
}
#Search #Search-Bar .search-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 5px;
}
#Search #Search-Bar .search-container label {
  position: absolute;
  color: crimson;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  padding: 2px 10px;
  border-radius: 99px;
  right: 50px;
  bottom: -50%;
  z-index: 999;
  font-size: 14px;
}
#Search #Search-Bar .search-container input#Search-Box {
  border: 0;
  outline: 0;
  margin-right: 8px;
  padding: 8px 4px;
  font-size: 17px;
  letter-spacing: 0.5px;
  height: 100%;
  width: -moz-max-content;
  width: max-content;
  text-align: right;
  font-weight: 300;
  color: #005681;
  background-color: none;
  transition: 0.2s;
}
#Search #Search-Bar .search-container input#Search-Box::-webkit-search-cancel-button {
  display: inline-block;
  margin: 0 0.5rem;
  font-size: 130%;
}
#Search #Search-Bar .search-container input#Search-Box:hover, #Search #Search-Bar .search-container input#Search-Box:focus {
  padding: 10px 0;
  font-size: 1.25rem;
}
#Search #Search-Bar #Search-Results-Box {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 901;
  height: 0;
  padding: 0 8px;
  opacity: 0;
  width: 95vw;
  max-width: 600px;
  overflow-y: scroll;
  overscroll-behavior: contain;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  transition: 0.3s;
}
#Search #Search-Bar #Search-Results-Box.show {
  height: auto;
  max-height: 500px;
  padding: 8px;
  opacity: 1;
}
#Search #Search-Bar #Search-Results-Box .result {
  display: flex;
  padding: 14px 12px;
  width: 100%;
  margin-bottom: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
  border: 3px solid transparent;
  border-radius: 5px;
}
#Search #Search-Bar #Search-Results-Box .result:hover, #Search #Search-Bar #Search-Results-Box .result:focus {
  border: 3px solid #1abfd4;
}
#Search #Search-Bar #Search-Results-Box .result .result-img {
  display: flex;
  align-items: center;
  width: 70px;
  padding: 5px;
  margin-right: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
#Search #Search-Bar #Search-Results-Box .result .result-img svg,
#Search #Search-Bar #Search-Results-Box .result .result-img iconify-icon {
  font-size: 36px;
  color: #005681;
  opacity: 0.8;
}
#Search #Search-Bar #Search-Results-Box .result h1 {
  all: unset;
  font-weight: 500;
  color: #005681;
  font-size: 22px;
  line-height: 50%;
  margin: 0;
  height: auto;
}
#Search #Search-Bar #Search-Results-Box .result p {
  margin: 0;
  font-size: 15px;
  font-weight: 300;
}
#Search #Search-Bar #Search-Results-Box .result p.type {
  opacity: 0.5;
  line-height: 100%;
}
#Search #Search-Bar #Search-Results-Box::-webkit-scrollbar {
  all: unset;
  background-color: none;
  width: 8px;
}
#Search #Search-Bar #Search-Results-Box::-webkit-scrollbar-thumb {
  background-color: #005681;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 99px;
}
#Search #Search-Bar #Search-Results-Box::-webkit-scrollbar-track {
  margin: 10px;
  background-color: none;
}

header#Header {
  z-index: 900;
  display: flex;
  position: sticky;
  top: -1px;
  left: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4rem;
  transition: all 0.3s;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}
header#Header .barrier {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: 0 30px;
}
header#Header .barrier .logo-box {
  display: flex;
  align-items: center;
  height: 100%;
}
header#Header .barrier .logo-box .img-wrap {
  filter: drop-shadow(0 5px 6px rgba(0, 0, 0, 0.3));
}
header#Header .barrier .logo-box .img-wrap svg {
  background-color: white;
  padding: 0.7em 0.45em 1.1em 0.45em;
  transform: translateY(0.35rem);
  transition: transform 0.3s;
  width: 70px;
  height: auto;
  margin-right: 10px;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 50% 100%, 0% 85%);
          clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 50% 100%, 0% 85%);
}
header#Header .barrier .logo-box h1 {
  font-weight: 500;
  font-family: "Lexend";
  color: #005681;
}
header#Header .barrier .logo-box h1 span {
  position: absolute;
  font-size: 20px;
}
header#Header .barrier .mobileButtons {
  display: flex;
  align-items: center;
}
header#Header .barrier .mobileButtons a {
  font-size: 28px;
  margin-right: 1rem;
  color: #005681;
}
header#Header .barrier .mobileButtons button:first-of-type {
  all: unset;
  font-size: 28px;
  margin-right: 1rem;
  cursor: pointer;
  color: hotpink;
  filter: drop-shadow(0 0 0.2rem rgba(0, 0, 0, 0.1));
}
header#Header .barrier .mobileButtons #burger {
  display: none;
  background-color: unset;
  border: unset;
  padding: 5px;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
header#Header .barrier .mobileButtons #burger .line {
  transition: 0.5s;
  height: 4px;
  width: 28px;
  margin: 5px 0;
  border-radius: 10px;
  background-color: #005681;
}
header#Header .barrier .mobileButtons .burger-x .top {
  transform: rotate(-45deg) translateY(14px);
}
header#Header .barrier .mobileButtons .burger-x .mid {
  transform: translateX(200%);
  opacity: 0;
}
header#Header .barrier .mobileButtons .burger-x .bott {
  transform: rotate(45deg) translateY(-14px);
  width: 15px;
}
header#Header .barrier nav {
  height: auto;
  display: flex;
}
header#Header .barrier nav.mobile-nav {
  transition: 0.5s;
  position: fixed;
  top: -100vh;
  height: 100vh;
  left: 0;
  width: 100vw;
  opacity: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
header#Header .barrier nav.mobile-nav .nav-item {
  display: block;
  height: auto;
  font-size: clamp(20px, 4vw, 26px);
  margin: 10px 0;
  padding: 2vh;
}
header#Header .barrier nav.mobile-nav .nav-item.outline {
  padding: 0.8em 1.5em;
}
header#Header .barrier nav.mobile-nav.mobile-open {
  top: 0;
  opacity: 1;
}
header#Header .barrier nav.mobile-nav.mobile-open #Nav-X-Btn {
  visibility: visible;
}
header#Header .barrier nav .nav-item {
  font-weight: 500;
  font-size: 1rem;
  color: #005681;
  display: table-cell;
  height: 100%;
  text-decoration: none;
  padding: 0.6rem 1.25rem;
  transition: color 0.2s;
  letter-spacing: 0.02rem;
}
header#Header .barrier nav .nav-item:hover, header#Header .barrier nav .nav-item:focus {
  color: #1abfd4;
  outline: none;
}
header#Header .barrier nav .nav-item.outline {
  background: linear-gradient(to top right, #005681, #1abfd4);
  padding: 0.5rem 1.2rem;
  color: #fefefe;
  border-radius: 99px;
  transition: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}
header#Header .barrier nav .nav-item.outline:hover {
  background: transparent;
  outline: 2px solid #1abfd4;
  color: #005681;
}
header#Header .barrier nav .nav-item#Nav-X-Btn {
  z-index: 999;
  all: unset;
  position: fixed;
  top: 5vh;
  right: 8vw;
  font-size: 2rem;
  cursor: pointer;
  visibility: hidden;
}
header#Header .barrier nav .dropdown-menu {
  box-shadow: 2px 2px 10px dimgray;
  background-color: rgba(255, 255, 255, 0.9);
  outline: none;
}
header#Header .barrier nav .dropdown-menu .dropdown-item {
  display: flex;
  justify-content: space-between;
  color: #005681;
  transition: 0.1s;
  font-size: 18px;
}
header#Header .barrier nav .dropdown-menu .dropdown-item svg {
  margin: auto 0;
  margin-right: 15px;
}
header#Header .barrier nav .dropdown-menu .dropdown-item:hover {
  background-color: rgba(0, 86, 129, 0.7529411765);
  color: #fefefe;
}
header#Header #Phone-Strip {
  background: linear-gradient(to right, #30387e, #0199C4);
  box-shadow: 0 0.4rem 0.5rem rgba(0, 0, 0, 0.3);
  padding: 2px 0;
  position: absolute;
  width: 100%;
  top: 100%;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  transition: 0.4s;
}
header#Header #Phone-Strip.show {
  visibility: visible;
  opacity: 1;
}
header#Header #Phone-Strip a {
  color: white;
  margin-left: 5rem;
}

header#Header.scrolling:not(.mobile-open-header) .logo-box img {
  transform: scale(0.7);
}

#UpdateMessage {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#UpdateMessage p {
  font-family: sans-serif;
  text-align: center;
  font-size: medium;
  color: crimson;
}
#UpdateMessage button {
  all: unset;
  opacity: 0.8;
  text-decoration: underline;
  cursor: pointer;
}

#to-top {
  all: unset;
  display: none;
  transition: 0.3s;
  cursor: pointer;
  font-size: 50px;
  color: #59c593;
  position: fixed;
  bottom: 10vh;
  right: 50%;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2));
}
#to-top:hover {
  color: #2d8850;
}

footer {
  background-color: #005681;
  background: linear-gradient(to bottom, #005681, rgb(17, 39, 71));
  color: #fefefe;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}
footer p {
  color: #fefefe;
  font-size: 18px;
}
footer a {
  color: #fefefe;
  font-size: 16px;
  font-weight: 300;
}
footer a:hover {
  color: #59c593;
}
footer .links {
  padding: 3rem 0;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 2rem;
}
footer .links .box {
  display: flex;
  flex-direction: column;
}
footer .links .box .TN {
  margin: 5px 0;
  height: 24px;
  width: 24px;
}
footer .links .box .logo-box {
  display: flex;
  align-items: center;
  background-color: white;
  width: -moz-max-content;
  width: max-content;
  padding: 2px 15px;
  border-radius: 99px;
}
footer .links .box .logo-box svg {
  width: 30px;
  height: unset;
  padding: 1px;
  margin-right: 2px;
}
footer .links .box .logo-box h2 {
  font-size: 1.8em;
  font-weight: 300;
  margin: 0;
  color: #005681;
}
footer .links .box .logo-box h2 span {
  font-size: 0.75em;
}
footer .links .box h2 {
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0.025rem;
}
footer .links .box h2 span {
  font-size: 16px;
}
footer .links .box .socials-box a {
  font-size: 20px;
  padding: 0.5rem;
  margin-right: 0.25rem;
}
footer .links .box .socials-box a:first-of-type {
  padding-left: 0;
}
footer .copyright-box {
  padding: 1rem 0;
  border-top: 1px solid #fefefe;
  display: flex;
  justify-content: space-between;
}
footer .copyright-box p,
footer .copyright-box a {
  font-size: 14px;
  font-weight: 300;
}
footer .copyright-box .legal-box a {
  margin-left: 1rem;
}

@supports (-webkit-touch-callout: none) {
  header#Header .barrier .logo-box .img-wrap {
    filter: unset;
  }
  header#Header .barrier .logo-box .img-wrap svg {
    background: none;
    margin: 0 0.25rem 0 0;
    padding: unset;
    width: 50px;
    transform: unset;
  }
}
@media screen and (max-width: 991px) {
  header .barrier #burger {
    display: flex;
  }
  #Phone-Strip {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  #Top-header .container {
    justify-content: center;
  }
  #Top-header .container .announcement {
    display: none;
  }
  #Top-header .container .options-box {
    display: none;
  }
  #Search-Bar {
    justify-content: center !important;
    max-width: unset;
  }
  #Search-Bar .socials-box {
    display: none !important;
  }
  #Search-Bar #Search-Box {
    text-align: center !important;
  }
  #Search-Bar #Search-Box::-webkit-search-cancel-button {
    display: block !important;
  }
  footer .container,
  footer .container .box {
    text-align: center;
  }
  footer .container .logo-box,
  footer .container .box .logo-box {
    margin: 1rem auto;
  }
  footer .container .socials-box,
  footer .container .box .socials-box {
    margin-top: 20px;
  }
  footer .container .socials-box a,
  footer .container .socials-box a:first-of-type,
  footer .container .box .socials-box a,
  footer .container .box .socials-box a:first-of-type {
    font-size: 100%;
    margin: 6px;
    padding: 10px;
  }
  footer .links {
    grid-auto-flow: row;
    padding: 3rem 15px;
  }
  footer .copyright-box {
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 500px) {
  header#Header .barrier {
    margin: 0;
  }
  header#Header .barrier .logo-box h1 {
    display: block;
    font-size: 8vw;
    margin: 0;
    padding: 0;
  }
  header#Header .barrier #burger {
    margin-right: 7vw;
  }
}
@media screen and (max-width: 400px) {
  #Top-header .container .phone-box a,
  #Top-header .container .phone-box svg {
    font-size: 7vw;
  }
  header#Header .barrier {
    margin: 0;
  }
  header#Header .barrier .logo-box h1 {
    display: none;
  }
}
@keyframes blink {
  from {
    opacity: 0.2;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}/*# sourceMappingURL=index.css.map */