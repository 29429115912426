@use '../globals';

// #region === General Styles ===
@mixin flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

html {
    box-sizing: border-box;
}

body,
html {
    width: 100%;
    height: auto;

    &.prevent-scroll {
        overflow-y: hidden;
    }

    &::-webkit-scrollbar {
        all: unset;
        width: 14px;
    }

    &::-webkit-scrollbar-track {
        all: unset;
    }

    &::-webkit-scrollbar-thumb {
        all: unset;
        background-color: globals.$vividBlue;
    }
}

body {
    overflow-x: hidden;
}

body,
p {
    color: globals.$mainBlue;
    font: 400 1.5rem/1.8rem "Lexend", sans-serif;
}

.p-large {
    font: 400 1.125rem/1.75rem "Lexend", sans-serif;
}

.p-small {
    font: 400 0.875rem/1.5rem "Lexend", sans-serif;
}

section {
    text-align: center;
    scroll-margin-top: globals.$scrollMargin;
    width: 100%;
    overflow: hidden;
}

// HEADINGS

h1 {
    font: 700 2.5rem/3.125rem "Lexend", sans-serif;
    letter-spacing: -0.2px;
}

h2 {
    font: 700 2rem/2.625rem "Lexend", sans-serif;
    letter-spacing: -0.2px;
}

h3 {
    font: 700 1.625rem/2.125rem "Lexend", sans-serif;
    letter-spacing: -0.2px;
}

h4 {
    font: 700 1.375rem/1.75rem "Lexend", sans-serif;
    letter-spacing: -0.1px;
}

h5 {
    font: 700 1.125rem/1.5rem "Lexend", sans-serif;
    letter-spacing: -0.1px;
}

h6 {
    font: 700 1rem/1.375rem "Lexend", sans-serif;
    letter-spacing: -0.1px;
}

// Anchors
a,
a:hover {
    text-decoration: none;
}

a.white {
    color: #fff;
}

.decorative-line {
    display: block;
    width: 5rem;
    height: 0.25rem;
    border-radius: 50px;
    background-color: globals.$mainBlue;
    margin: 16px auto;

    &.white {
        background-color: whitesmoke;
    }
}

.SPK-CTA {
    display: inline-block;
    width: fit-content;
    z-index: 1;
    font-weight: 400;
    font-size: 1.1rem;
    padding: .75em 1.75em;
    border: none;
    border-radius: 99px;
    background: globals.$blueGradient;
    color: white;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);

    &:hover,
    &:focus {
        color: globals.$mainBlue;
        background: none;
        box-shadow: 0 0 10px globals.$vividBlue;

        &::after {
            opacity: 1;
        }
    }
}

.btn-solid-reg {
    display: inline-block;
    padding: 1.1875rem 2.125rem 1.1875rem 2.125rem;
    border: 0.125rem solid globals.$mainBlue;
    border-radius: 2rem;
    background-color: globals.$mainBlue;
    color: #fff;
    font: 700 0.875rem/0 "Lexend", sans-serif;
    transition: all 0.2s;

    &:hover {
        background-color: transparent;
        color: globals.$mainBlue;
    }
}

.btn-solid-lg {
    display: inline-block;
    padding: 20px 35px;
    border: 0.125rem solid globals.$mainBlue;
    border-radius: 20rem;
    background-color: globals.$mainBlue;
    color: #fff;
    font: 700 0.875rem/0 "Lexend", sans-serif;
    transition: all 0.2s;

    &:hover {
        background-color: transparent;
        color: globals.$mainBlue;
    }
}

.btn-outline-reg {
    display: inline-block;
    padding: 1.1875rem 2.125rem 1.1875rem 2.125rem;
    border: 0.125rem solid globals.$mainBlue;
    border-radius: 2rem;
    background-color: transparent;
    color: globals.$mainBlue;
    font: 700 0.875rem/0 "Lexend", sans-serif;
    transition: all 0.2s;

    &:hover {
        background-color: globals.$mainBlue;
        color: #fff;
    }
}

.btn-outline-lg {
    display: inline-block;
    padding: 1.375rem 2.625rem 1.375rem 2.625rem;
    border: 0.125rem solid globals.$mainBlue;
    border-radius: 2rem;
    background-color: transparent;
    color: globals.$mainBlue;
    font: 700 0.875rem/0 "Lexend", sans-serif;
    transition: all 0.2s;

    &:hover {
        background-color: globals.$mainBlue;
        color: #fff;
    }
}

.btn-outline-sm {
    display: inline-block;
    padding: 0.875rem 1.5rem 0.875rem 1.5rem;
    border: 0.125rem solid globals.$mainBlue;
    border-radius: 2rem;
    background-color: transparent;
    color: globals.$mainBlue;
    font: 700 0.875rem/0 "Lexend", sans-serif;
    transition: all 0.2s;

    &:hover {
        background-color: globals.$mainBlue;
        color: #fff;
    }
}

.notFound {
    margin: 20vh auto;

    p {
        font-size: 1rem;
    }
}

// #endregion General Styles

.Policy {
    padding: 8vh 1vh;
    text-align: start;

    *:not(h1) {
        text-align: left;
        font-size: 20px;
    }

    .logo {
        // width: auto;
        max-width: 100%;
    }

    .iconify {
        font-size: 5rem;
    }

    p,
    a,
    ol li,
    .accordion {
        margin: 3vh auto;
        font-weight: 300;
    }

    p.title {
        font-weight: 400;
        text-transform: uppercase;
    }

    .contents-links {
        a {
            display: block;
            margin: 0 0 5px 0;
        }
    }

    .accordion-button,
    .accordion-body {
        color: globals.$mainBlue;
    }

    .signature {
        p {
            text-align: right;
            margin: 0 auto;
        }
    }
}

// #region ===HEADERS===
// #region TOP HEADER
#Top-header {
    z-index: 901;
    height: globals.$topHeaderSize;
    width: 100%;
    background-color: globals.$mainBlue;
    background: globals.$blueFancyGradient;
    display: flex;
    justify-content: center;
    align-items: center;

    * {
        font-family: "Lexend";
        color: globals.$iron;
        margin: 0;
    }

    .container {
        display: flex;
        justify-content: space-between;
    }

    .phone-box {
        a {
            font-family: 'Lexend', Tahoma, Geneva, Verdana, sans-serif;
            font-weight: 700;
            font-size: 28px;
        }
    }

    .announcement {
        display: flex;
        justify-content: space-between;

        p {
            font-size: 16px;
            font-weight: 300;
        }

        a {
            text-decoration: underline;
            padding: 10px 0;
        }

        #HolidayIcon {
            position: relative;
            display: flex;
            padding: 3px;
            margin-right: 8px;
            filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));

            p {
                position: absolute;
                top: 0;
                background-color: white;
                border-radius: 99px;
                padding: 5px 10px;
                width: max-content;
                z-index: 999;
                color: globals.$mainBlue;
                font-weight: 500;
                font-size: 14px;
                opacity: 0;
                right: 50%;
                transition: 0.2s;
                scale: 0.8;
            }

            &:hover {
                p {
                    right: 110%;
                    opacity: 1;
                    scale: 1;
                }
            }

        }
    }

    .options-box {
        font-weight: 300;
        font-size: 16px;
        letter-spacing: .5px;

        a {
            margin-left: 30px;
            padding: 10px 0;
        }
    }
}

// #endregion Top Header

// #region SEARCH 
#Search {
    background-color: white;

    #Search-Bar {
        display: flex;
        position: relative;
        justify-content: space-between;

        .socials-box {
            display: flex;
            align-items: center;

            a {
                margin-right: 1.4rem;
                padding-right: 5px;
                font-size: 1.2rem;
                color: globals.$mainBlue;

                &:hover,
                &:focus {
                    color: globals.$vividBlue;
                }
            }
        }

        .search-container {
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 0 5px;
            // border-bottom: 1px solid rgba(0, 0, 0, 0.2);

            label {
                position: absolute;
                color: crimson;
                background-color: white;
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
                padding: 2px 10px;
                border-radius: 99px;
                right: 50px;
                bottom: -50%;
                z-index: 999;
                font-size: 14px;
            }

            input#Search-Box {
                border: 0;
                outline: 0;
                margin-right: 8px;
                padding: 8px 4px;
                font-size: 17px;
                letter-spacing: .5px;
                height: 100%;
                width: max-content;
                text-align: right;
                font-weight: 300;
                color: globals.$mainBlue;
                background-color: none;
                transition: .2s;

                &::-webkit-search-cancel-button {
                    display: inline-block;
                    margin: 0 .5rem;
                    font-size: 130%;
                }

                &:hover,
                &:focus {
                    padding: 10px 0;
                    font-size: 1.25rem;
                }
            }
        }

        #Search-Results-Box {
            position: absolute;
            top: 100%;
            right: 0;
            z-index: 901;
            height: 0;
            padding: 0 8px;
            opacity: 0;
            width: 95vw;
            max-width: 600px;
            overflow-y: scroll;
            overscroll-behavior: contain;
            background-color: rgba(255, 255, 255, 0.9);
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
            border-radius: 15px;
            transition: .3s;

            &.show {
                height: auto;
                max-height: 500px;
                padding: 8px;
                opacity: 1;
            }

            .result {
                display: flex;
                padding: 14px 12px;
                width: 100%;
                margin-bottom: 5px;
                background-color: rgba(255, 255, 255, 0.5);
                box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
                border: 3px solid transparent;
                border-radius: 5px;

                &:hover,
                &:focus {
                    border: 3px solid globals.$vividBlue;
                }

                .result-img {
                    // For if the result has an image
                    display: flex;
                    align-items: center;
                    width: 70px;
                    padding: 5px;
                    margin-right: 5px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;

                    svg,
                    iconify-icon {
                        // Icons
                        font-size: 36px;
                        // default color
                        // Add color to result objects?
                        color: globals.$mainBlue;
                        opacity: .8;
                    }
                }


                h1 {
                    all: unset;
                    font-weight: 500;
                    color: globals.$mainBlue;
                    font-size: 22px;
                    line-height: 50%;
                    margin: 0;
                    height: auto;
                }

                p {
                    margin: 0;
                    font-size: 15px;
                    font-weight: 300;
                }

                p.type {
                    opacity: .5;
                    line-height: 100%;
                }
            }

            &::-webkit-scrollbar {
                all: unset;
                background-color: none;
                width: 8px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: globals.$mainBlue;
                box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 99px;
            }

            &::-webkit-scrollbar-track {
                margin: 10px;
                background-color: none;
            }
        }
    }
}

// #endregion Search Bar
// #endregion Search

// #region NAV HEADER
header#Header {
    z-index: 900;
    display: flex;
    position: sticky;
    top: -1px;
    left: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 4rem;
    transition: all .3s;
    background-color: white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);

    .barrier {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        margin: 0 30px;

        .logo-box {
            display: flex;
            align-items: center;
            height: 100%;

            .img-wrap {
                filter: drop-shadow(0 5px 6px rgba(0, 0, 0, .3));

                svg {
                    background-color: white;
                    padding: .7em .45em 1.1em .45em;
                    transform: translateY(.35rem);
                    transition: transform .3s;
                    width: 70px;
                    height: auto;
                    margin-right: 10px;
                    clip-path: polygon(0%0%, 100%0%, 100%85%, 50%100%, 0%85%);
                }
            }


            h1 {
                font-weight: 500;
                font-family: "Lexend";
                color: globals.$mainBlue;

                span {
                    position: absolute;
                    font-size: 20px;
                }
            }
        }

        .mobileButtons {
            display: flex;
            align-items: center;

            a {
                font-size: 28px;
                margin-right: 1rem;
                color: globals.$mainBlue;
            }

            button:first-of-type {
                all: unset;
                font-size: 28px;
                margin-right: 1rem;
                cursor: pointer;
                color: hotpink;
                filter: drop-shadow(0 0 .2rem rgba(0, 0, 0, 0.1));
            }

            #burger {
                display: none;
                background-color: unset;
                border: unset;
                padding: 5px;
                flex-direction: column;
                justify-content: space-between;
                cursor: pointer;

                .line {
                    transition: .5s;
                    height: 4px;
                    width: 28px;
                    margin: 5px 0;
                    border-radius: 10px;
                    background-color: globals.$mainBlue;
                }
            }

            .burger-x {
                .top {
                    transform: rotate(-45deg) translateY(14px);
                }

                .mid {
                    transform: translateX(200%);
                    opacity: 0;
                }

                .bott {
                    transform: rotate(45deg) translateY(-14px);
                    width: 15px;
                }
            }
        }



        nav {
            height: auto;
            display: flex;

            // Mobile Styling
            &.mobile-nav {
                // Applied to NAV when mobile width is reached
                transition: .5s;
                position: fixed;
                top: -100vh;
                height: 100vh;
                left: 0;
                width: 100vw;
                opacity: 0;
                background-color: white;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .nav-item {
                    display: block;
                    height: auto;
                    font-size: clamp(20px, 4vw, 26px);
                    margin: 10px 0;
                    padding: 2vh;

                    &.outline {
                        // Mobile menu contact button
                        padding: .8em 1.5em;
                    }
                }

                &.mobile-open {
                    // Applied to NAV when burger is clicked & menu opens.
                    top: 0;
                    opacity: 1;

                    #Nav-X-Btn {
                        visibility: visible;
                    }
                }
            }

            .nav-item {
                font-weight: 500;
                font-size: 1rem;
                color: globals.$mainBlue;
                display: table-cell;
                height: 100%;
                text-decoration: none;
                padding: .6rem 1.25rem;
                transition: color .2s;
                letter-spacing: .02rem;

                &:hover,
                &:focus {
                    color: globals.$vividBlue;
                    outline: none;
                }

                &.outline {
                    // CTA 'Contact' button
                    background: globals.$blueGradient;
                    padding: .5rem 1.2rem;
                    color: globals.$iron;
                    border-radius: 99px;
                    transition: none;
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);

                    &:hover {
                        background: transparent;
                        outline: 2px solid globals.$vividBlue;
                        color: globals.$mainBlue;
                    }
                }

                &#Nav-X-Btn {
                    z-index: 999;
                    all: unset;
                    position: fixed;
                    top: 5vh;
                    right: 8vw;
                    font-size: 2rem;
                    cursor: pointer;
                    visibility: hidden;
                }
            }

            .dropdown-menu {
                box-shadow: 2px 2px 10px dimgray;
                background-color: rgba(255, 255, 255, 0.9);
                outline: none;

                .dropdown-item {
                    display: flex;
                    justify-content: space-between;
                    color: globals.$mainBlue;
                    transition: .1s;
                    font-size: 18px;

                    svg {
                        margin: auto 0;
                        margin-right: 15px;
                    }

                    &:hover {
                        background-color: #005681c0;
                        color: globals.$iron;
                    }
                }
            }
        }
    }

    #Phone-Strip {
        background: globals.$blueFancyGradient;
        box-shadow: 0 .4rem .5rem rgba(0, 0, 0, .3);
        padding: 2px 0;
        position: absolute;
        width: 100%;
        top: 100%;
        z-index: -1;
        visibility: hidden;
        opacity: 0;
        transition: .4s;

        &.show {
            visibility: visible;
            opacity: 1;
        }

        a {
            color: white;
            margin-left: 5rem;
        }
    }
}

// SCROLLING

header#Header.scrolling:not(.mobile-open-header) {
    .logo-box img {
        transform: scale(.7);
    }
}

#UpdateMessage {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        font-family: sans-serif;
        text-align: center;
        font-size: medium;
        color: crimson;
    }

    button {
        all: unset;
        opacity: .8;
        text-decoration: underline;
        cursor: pointer;
    }
}

#to-top {
    // Back to top button - Hiding because of addition of live-chat.
    all: unset;
    display: none;
    transition: .3s;
    cursor: pointer;
    font-size: 50px;
    color: globals.$lightGreen;
    position: fixed;
    bottom: 10vh;
    right: 50%;
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .2));

    &:hover {
        color: globals.$greenie;
    }
}

// #endregion NavHeader
// #endregion Headers

// #region === FOOTER ===
footer {
    background-color: globals.$mainBlue;
    background: linear-gradient(to bottom, globals.$mainBlue, rgb(17, 39, 71));
    color: globals.$iron;
    box-shadow: 0 0 15px rgba(0, 0, 0, .5);

    p {
        color: globals.$iron;
        font-size: 18px;
    }

    a {
        color: globals.$iron;
        font-size: 16px;
        font-weight: 300;

        &:hover {
            color: globals.$lightGreen;
        }
    }

    .links {
        padding: 3rem 0;
        display: grid;
        grid-auto-flow: column;
        grid-gap: 2rem;

        .box {
            display: flex;
            flex-direction: column;

            .TN {
                margin: 5px 0;
                height: 24px;
                width: 24px;
            }

            .logo-box {
                display: flex;
                align-items: center;
                background-color: white;
                width: max-content;
                padding: 2px 15px;
                border-radius: 99px;


                svg {
                    width: 30px;
                    height: unset;
                    padding: 1px;
                    margin-right: 2px;
                }

                h2 {
                    font-size: 1.8em;
                    font-weight: 300;
                    margin: 0;
                    color: globals.$mainBlue;

                    span {
                        font-size: .75em;
                    }
                }
            }

            h2 {
                font-size: 22px;
                font-weight: 300;
                letter-spacing: .025rem;

                span {
                    font-size: 16px;
                }
            }

            .socials-box {
                a {
                    font-size: 20px;
                    padding: .5rem;
                    margin-right: .25rem;
                }

                a:first-of-type {
                    padding-left: 0;
                }
            }
        }
    }

    .copyright-box {
        padding: 1rem 0;
        border-top: 1px solid globals.$iron;
        display: flex;
        justify-content: space-between;

        p,
        a {
            font-size: 14px;
            font-weight: 300;
        }

        .legal-box a {
            margin-left: 1rem;
        }
    }
}

// #endregion Footer

// MEDIA QUERIES
// For Non-webkit browsers - eg Safari
@supports (-webkit-touch-callout: none) {

    // Remove the flag behind the logo. Doesn't clip properly.
    header#Header .barrier .logo-box .img-wrap {
        filter: unset;

        svg {
            background: none;
            margin: 0 .25rem 0 0;
            padding: unset;
            width: 50px;
            transform: unset;
        }
    }
}

@media screen and (max-width: 991px) {
    header {
        .barrier {
            #burger {
                display: flex;
            }
        }
    }

    #Phone-Strip {
        display: none;
    }

}

@media screen and (max-width: 768px) {
    #Top-header {
        .container {
            justify-content: center;

            .announcement {
                display: none;
            }

            .options-box {
                display: none;
            }
        }
    }

    #Search-Bar {
        justify-content: center !important;
        max-width: unset;

        .socials-box {
            display: none !important;
        }


        #Search-Box {
            text-align: center !important;

            &::-webkit-search-cancel-button {
                display: block !important;
            }
        }
    }



    footer {

        .container,
        .container .box {
            text-align: center;

            .logo-box {
                margin: 1rem auto;
            }

            .socials-box {
                margin-top: 20px;

                a,
                a:first-of-type {
                    font-size: 100%;
                    margin: 6px;
                    padding: 10px;
                }
            }
        }

        .links {
            grid-auto-flow: row;
            padding: 3rem 15px;
        }

        .copyright-box {
            flex-direction: column;
            align-items: center;
        }
    }
}

@media screen and (max-width: 500px) {
    header#Header .barrier {
        margin: 0;

        .logo-box {
            h1 {
                display: block;
                font-size: 8vw;
                margin: 0;
                padding: 0;
            }

        }

        #burger {
            margin-right: 7vw;
        }
    }
}

@media screen and (max-width: 400px) {
    #Top-header {
        .container .phone-box {

            a,
            svg {
                font-size: 7vw;
            }
        }
    }

    header#Header .barrier {
        margin: 0;

        .logo-box {
            h1 {
                display: none;
            }
        }
    }
}

// #endregion Media Queries

@keyframes blink {
    from {
        opacity: 0.2;
        transform: scale(.9);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}